import * as styles from '../styles/courses.module.scss';

import CourseCard from '../components/CourseCard';
import Layout from '../components/Layout';
import React from 'react';
import ScrollToTopButton from '../components/ScrollToTopButton/ScrollToTopButton';
import SectionTitle from '../components/SectionTitle';
import Seo from '../components/SEO';
import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

const Fitness = ({ data }) => {
  const fitnessCourses = data.fitnessCourses.nodes;

  return (
    <Layout>
      <Seo title={'Pilart - Fitness'} description={'Scopri i nostri corsi di fitness!'} />
      <SectionTitle
        title="CORSI DI FITNESS"
        subtitle={
          "Prendersi cura del proprio corpo significa tonificare e allungare i muscoli, migliorare la postura e l'agilità, ma anche raggiungere uno stato di benessere e di equilibrio generale.\n In questa direzione vanno tutti i corsi-fitness proposti da Pilart"
        }
      />
      <div className={styles.courses}>
        {fitnessCourses.map((course) => {
          const featuredImage = getImage(course.frontmatter?.featuredImg?.childImageSharp?.gatsbyImageData);

          return (
            <CourseCard
              key={course.frontmatter?.slug}
              featuredImage={featuredImage}
              title={course.frontmatter?.title}
              slug={course.frontmatter?.slug}
              type={course.frontmatter?.type}
            />
          );
        })}
      </div>
      <ScrollToTopButton />
    </Layout>
  );
};

export default Fitness;

export const query = graphql`
  query FitnessPage {
    fitnessCourses: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "fitness-course" } } }
      sort: { order: ASC, fields: frontmatter___title }
    ) {
      nodes {
        frontmatter {
          type
          title
          slug
          featuredImg {
            childImageSharp {
              gatsbyImageData(width: 400, height: 300, quality: 90, placeholder: BLURRED, formats: AUTO)
            }
          }
          description
        }
        id
      }
    }
  }
`;
