import * as styles from './course-card.module.scss';

import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import React from 'react';

const CourseCard = ({ featuredImage, title, slug, type }) => {
  return (
    <>
      <div className={styles.courseWrapper} key={slug}>
        <Link to={`/${type.split('-')[0]}/${slug}`}>
          <div className={styles.imageWrapper}>
            <div className={styles.cardOverlay} />
            <GatsbyImage image={featuredImage} alt={title} className={styles.image} />
          </div>
          <h3 className={styles.courseName}>{title}</h3>
        </Link>
      </div>
    </>
  );
};

export default CourseCard;
