import * as styles from './section-title.module.scss';

import React from 'react';

const SectionTitle = ({ title, subtitle = null }) => {
  return (
    <>
      <span className={styles.title}>
        <h2>
          <span>{title}</span>
        </h2>
      </span>
      {subtitle && <p className={styles.subtitle}> {subtitle} </p>}
    </>
  );
};

export default SectionTitle;
